@import '../../shared/settings';

.color-filter {
    a {
        display: block;
        margin: 8px 0;
        line-height: 20px;
        color: $dark-grey;
    }

    .color {
        float: left;
        margin-right: 10px;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3); /* Semi-transparante rand */

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .color-multicolor {
        background: conic-gradient(
            #f1c40f 0% 12.5%,
            #43ad4b 12.5% 25%,
            #6db4f0 25% 37.5%,
            #2586d8 37.5% 50%,
            #8e44ad 50% 62.5%,
            #f079c7 62.5% 75%,
            #ff901f 75% 87.5%,
            #e74c3c 87.5% 100%
        );
    }
}
