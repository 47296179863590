@import '../../shared/settings';

.histogram-filter {
    padding-bottom: 6px;
}

.histogram-filter-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;

    input[type='text'] {
        &[data-size='0'],
        &[data-size='1'],
        &[data-size='2'],
        &[data-size='3'],
        &[data-size='4'] {
            width: 52px;
        }
        &[data-size='5'] {
            width: 60px;
        }
        &[data-size='6'] {
            width: 68px;
        }
        &[data-size='7'] {
            width: 76px;
        }
        &[data-size='8'] {
            width: 84px;
        }
    }
}

.input-range {
    position: relative;
    height: 4px;
    width: calc(100% - 18px);
    margin-left: 4px;
}

.input-range__track {
    display: block;
    position: relative;
    background: rgba($light-grey, 0.5);
    cursor: pointer;
    height: 4px;
}

.input-range__track--active {
    background: $brand;
}

.input-range__slider {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    margin-right: 10px;
    margin-left: -8px;
    outline: none;
    border: 5px solid $brand;
    border-radius: 100%;
    box-shadow: 0 0 0 2px white;
    background: white;
    cursor: pointer;
    width: 6px;
    height: 6px;
    appearance: none;

    &:active {
        transform: scale(1.1);
    }
}

.input-range__label {
    display: none;
    transform: translateZ(0);
    white-space: nowrap;
    font-size: 12px;
}

.input-range__label--min,
.input-range__label--max {
    position: absolute;
    bottom: -30px;
}

.input-range__label--min {
    left: 0;
}

.input-range__label-container {
    position: relative;
    left: -50%;
}

.input-range__label--max {
    right: 0;

    .input-range__label-container {
        left: 50%;
    }
}

.input-range__label--value {
    position: absolute;
    top: -33px;
}

.input-range--disabled {
    .input-range__track,
    .input-range__slider {
        opacity: 0.5;
    }
}
